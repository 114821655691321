export class AccionImpresiones {
  static AUDITORIA_AUXILIO_TRANSPORTE = 'reporteAuditoriaAuxiloTrasporte';
  static DESPRENDIBLE_NOMINA = 'desprendibleNomina';
  static RESUMEN_APORTES_RETENCIONES = 'informeAportesPrestacionesSociales';
  static AUDITORIA_APORTES_SOCIALES = 'reporteAporteSocialesNomina';
  static AUDITORIA_RETEFUENTE = 'desprendibleNomina';
  static INFORME_CONTABILIZACION = 'reporteNominaContabilizacion';
  static NOMINA = 'reporteNomina';
  static TICKET = 'obtenerTiquete';
  static INVENTARIO = 'obtenerInformacionImpresionInventarios';
  static CONTABLES = 'obtenerInformacionImpresionContables';
  static CONSIGNACION_BANCARIA =
    'obtenerInformacionImpresionConsignacionBancaria';
  static AJUSTES_INVENTARIO = 'obtenerInformacionImpresionInventario2';
  static SALDOS_INICIALES = 'obtenerInformacionImpresionSaldosIniciales';
  static DEPRECIACION = 'obtenerInformacionImpresionDepreciacion';
  static CANCELACION_CUENTAS = 'obtenerInformacionImpresionCancelacionCuentas';
  static BALANCE_GENERAL = 'obtenerInformacionContabilidadBalanceGeneral';
  static BALANCE_GENERAL_CC = 'obtenerInformacionContabilidadBalanceGeneralCC';
  static CUENTAS_CONTABLES =
    'obtenerInformacionContabilidadCuentasContablesISC';
  static IMPUESTOS = 'obtenerInformacionContabilidadImpuestoIVA';
  static LIBRO_AUX = 'obtenerInformacionContabilidadLibroAux';
  static LIBRO_AUX_AF = 'obtenerInformacionContabilidadLibroAuxAF';
  static LIBRO_AUX_DF = 'obtenerInformacionContabilidadLibroAuxDF';
  static LIBRO_AUX_CC = 'obtenerInformacionContabilidadLibroAuxCC';
  static LIBRO_AUX_CCM = 'obtenerInformacionContabilidadLibroAuxCCM';
  static LIBRO_AUX_CCMT = ' obtenerInformacionContabilidadLibroAuxMT';
  static LIBRO_AUX_CCT = 'obtenerInformacionContabilidadLibroAuxT';
  static LIBRO_AUX_CCNM = 'obtenerInformacionContabilidadLibroAuxCCNM';
  static CONCILIACION = 'obtenerInformacionConciliacion';
  static ACTIVO_FIJO = 'obtenerInformacionActivosFijos';
  static ESTADO_GENERICO_BLOQUEO = 'obtenerInformacionEstadoGenericoDocsBloq';
  static ESTADO_GENERICO_DESBLOQUEO =
    'obtenerInformacionEstadoGenericoDocsDesBloq';
  static INVENTARIOS = 'obtenerInformacionInventarios';
  static CONSOLIDADO_EXISTENCIAS = 'consolidadoDeExistenciasPorProducto';
  static INVENTARIOS_LISTADO = 'obtenerInformacionInventariosListado';
  static VENTAS = 'obtenerInformacionVentas';
  static VENTAS_PRODUCTO = 'obtenerInformacionVentasProductos';
  static VENTAS_ZONAS = 'obtenerInformacionVentasZonaCliente';
  static VENTAS_VENDEDOR = 'obtenerInformacionVentasClienteVendedor';
  static TERCERO = 'obtenerInformacionTerceros';
  static LIBRO_OFICIAL_DIARIO_DOCUMENTO = 'libroOficialDiarioDocumento';
  static LIBRO_OFICIAL_DIARIO_FECHA = 'libroOficialDiarioFecha';
  static ESTADO_RESULTADOS = 'obtenerInformacionContabilidadEstadoResultados';
  static ESTADO_RESULTADOS_CENTRO_COSTOS =
    'obtenerInformacionContabilidadEstadoResultadosCC';
  static ESTADO_RESULTADOS_EJECUTIVO = 'obtenerEstadoResultadoECC';
  static ESTADO_CUENTA_SALDOS_NORMAL =
    'obtenerInformacionContabilidadEstadoCuentaS';
  static ESTADO_CUENTA_SALDOS_CC =
    'obtenerInformacionContabilidadEstadoCuentaSCC';
  static ESTADO_CUENTA_SALDOS_CC_V =
    'obtenerInformacionContabilidadEstadoCuentaSCCV';
  static ESTADO_CUENTA_SALDOS_VENDEDOR =
    'obtenerInformacionContabilidadEstadoCuentaSV';
  static ESTADO_CUENTA_MOVIMIENTOS_NORMAL =
    'obtenerInformacionContabilidadEstadoCuentaM';

  static ESTADO_CUENTA_MOVIMIENTOS_CC =
    'obtenerInformacionContabilidadEstadoCuentaMCC';
  static ESTADO_CUENTA_MOVIMIENTOS_CC_V =
    'obtenerInformacionContabilidadEstadoCuentaMCCV';
  static ESTADO_CUENTA_MOVIMIENTOS_VENDEDOR =
    'obtenerInformacionContabilidadEstadoCuentaMV';
  static ESTADO_CUENTA_RECAUDO_CARTERA =
    'obtenerInformacionEstadoCuentaGestionCartera';
  static MATERIAL_REQUERIDO_EPT =
    'obtenerInformeMaterialRequerido';
}

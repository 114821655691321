import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Borrador, Columna, Opciones, PaginacionWo, PlataformaEnum, TipoDato, TipoInput } from '@wo/modelo';
import { ListenerBorradorService, PermisosTablaService, ScrollInfinitoService, TabsService } from '@wo/servicio/index';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { contentBalanza } from '../configuracion-general-balanzas.component';
import { BalanzaService } from '@wo/servicio/lib/balanzas/balanzas.service';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { FiltroTablasComponent } from '@wo/frontend/filtro-tablas/filtro-tablas.component';

@Component({
  selector: 'app-balanzas-predeterminadas',
  templateUrl: './balanzas-predeterminadas.component.html',
  styleUrls: [
    './balanzas-predeterminadas.component.css',
    '../../../wo-tabla/wo-tabla.component.css',
    '../../../documentos/listado/listado-documento-encabezado/listado-documento-encabezado.component.css'
  ]
})
export class BalanzasPredeterminadasComponent implements OnInit {
 tiposDatos:any[]=[
  TipoDato.LISTA,
  TipoDato.BOOLEAN
 ]
  private subscriptions = new Subscription();
  POS: boolean;
  screen: string;
  actualTab: string;
  public opcionesTabla = {
    crear: false,
    editable: false,
    paginado: false,
    menu: [],
    ordenar: false,
    desabilitado: false,
    vacio: false,
  };
  opcion: Opciones = new Opciones();
  paginacion: any;
  mostrarErrores: boolean;
  tablaEnum:string="listadoBalanza";
  datos:any[] = [];
  public columnas: Columna[] = [
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.balanza'  ),                atributo: 'descripcion',              tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: true,  /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.longitudTrama'),            atributo: 'longitudTrama',            tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.bandera'),                  atributo: 'bandera',                  tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.posInicialPeso'),           atributo: 'pesoInicialPos',           tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.longpeso'),                 atributo: 'longitudPeso',             tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.posInicialBandera'),        atributo: 'banderaInicialPos',        tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.longBandera'),              atributo: 'longitudBandera',          tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.separadorLinea'),           atributo: 'separadorLinea',           tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
    {  header: this.translateUtilService.getTranslateText('CONFIGURACION_BALANZA.configuracionComunicacion'),atributo: 'configuracionComunicacion',tipoDato: TipoDato.STRING,tipoInput: TipoInput.TEXT,soloLectura: false,hidden: false,editable: false, /*estilo: 'colWidth130', */ ordenar: false},
   ]
  borrador: any;
    //Filtros
    public isFinalClick:boolean = false;
    public ultimoFiltro: any = [];
    public ultimoFiltroAux: any = [];
    public ultimoOrden = { columna: 'id', orden: 'ASC' };
    public filtrosMostrar:any[]= [];

    @ViewChild(FiltroTablasComponent, { static: false })
    private filtroTablasComponent: FiltroTablasComponent;
  constructor(
    protected translateUtilService: TranslateUtilService,
    private listenerBorradorService: ListenerBorradorService,
    private popUpService: PopUpService,
    private permisosTabla: PermisosTablaService,
    private tabsService: TabsService,
    private route: ActivatedRoute,
    public _LoaderService: LoaderService,
    private scrollInfinitoService: ScrollInfinitoService,
    public _BalanzaService: BalanzaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) { 
    this.POS = plataforma == PlataformaEnum.POS;
    this.screen = this.route.snapshot.data.nombre;
    let sub2 = this.tabsService.cierraBorradorTab$.subscribe(respuesta => {
      if ('/panel/configuracion/balanzas-predeterminadas' === respuesta) {
        this.listenerBorradorService.actualizarBorrador(this.screen, new Borrador(this.borrador, 'PIERDEN_CAMBIOS'));

        if (this.borrador) {
          this.mostrarErrores = true;
        }
      }
    });
    this.subscriptions.add(sub2);
  }
  ngOnInit() {
    this.actualTab = this.tabsService.getOpenTabName();
    this.opcion.buscar = true;
    if (!this.POS) this.opcion.ayuda = true;
  }
  colocarMascara(){
    this._LoaderService.colocarMascara(this.actualTab, { tabla: false }, "loadingIndex", "paneTabs");
  }
  llenarTabla(pagina, registrosPorPagina, columna, orden, registroInicial?) {
    this.colocarMascara();
    const paginacion = new PaginacionWo(columna, pagina, registrosPorPagina, orden);

    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;


    paginacion.pagina = pagina ? pagina : 0;
    paginacion.registrosPorPagina = registrosPorPagina ? registrosPorPagina : 50;

    if (columna != undefined && orden != undefined) {
      this.ultimoOrden = { columna: columna, orden: orden };
    }

    paginacion.orden = orden;

    if (this.ultimoFiltro != undefined) {
      paginacion.filtros = this.ultimoFiltro.slice();
    }
    let sub = this._BalanzaService.getConfiguracionPredeterminda(paginacion).
      pipe(
        finalize(() => {
          this._LoaderService.quitarTodos();
        })
      )
      .subscribe({
        next: (response: any) => {
          let content:any[]=response.content
          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            pagina: response.pageable.pageNumber,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            registroInicial: registroInicial,
            orden: orden,
            columna: columna,
            filtros: paginacion.filtros
          };
          setTimeout(() => {
            this.datos = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.datos, content,this.isFinalClick);
          this.datos = this.datos.map(x => Object.assign({}, x));
          }, 10);
        },
        error: (error: any) => {
          this.errorHandlerComponent.handler(error)

        },
      });
      this.subscriptions.add(sub);
  }
  afterChange(e){
    if (e.pag === true) {
      e.orden = 'ASC'
      this.llenarTabla(e.pagActual, e.registrosPorPagina, e.columna, e.orden, e.registroInicial);
    }
  }


// -------------------------------------
respuestaFiltro(respuesta) {
  this.ultimoFiltro = respuesta;
  this.filtrosMostrar = this.ultimoFiltro.filter((item, index) => {
    return this.ultimoFiltro.indexOf(this.ultimoFiltro.find(({ nombreColumna }) => nombreColumna == item.nombreColumna)) === index;
  });
  if (this.ultimoFiltro) {
    this.ultimoFiltro.forEach(element => {
      if (element.atributo == 'impuestos.nombre') {
        if (element.valores) {
          element.valor = element.valores[0]
        }
      }
    });
  }
  this.ultimoFiltro = this.ultimoFiltro.map(x => Object.assign({}, x));
  this.datos = [];
  this.llenarTabla(
    0,
    this.paginacion.registrosPorPagina,
    this.ultimoOrden.columna,
    this.ultimoOrden.orden
  );
}
respuestaBoton(valor) {
  if (valor == 'buscar') {
    this.filtro();
  }
}

filtro() {
  let columnasFiltro = this.columnas.slice();
  columnasFiltro.splice(1, 1)
  this.filtroTablasComponent.abrirFiltro(columnasFiltro, this.ultimoFiltro);
}

borrarFiltro(filtro) {
  let position = this.ultimoFiltro.findIndex(v => v.nombreColumna == "Código");
  if (position != -1) {
    this.ultimoFiltro[position].valor /= 100;
    this.ultimoFiltro[position].valor2 /= 100;
  }
  this.ultimoFiltro = this.ultimoFiltro.filter(({ nombreColumna }) => nombreColumna != filtro.nombreColumna);

  let positionMostrar = this.filtrosMostrar.findIndex(v => v.nombreColumna == "Código");
  if (positionMostrar != -1) {
    this.filtrosMostrar[positionMostrar].valor /= 100;
    this.filtrosMostrar[positionMostrar].valor2 /= 100;
  }
  this.filtrosMostrar.splice(this.filtrosMostrar.findIndex(x => x == filtro), 1);
  this.datos = [];
  this.llenarTabla(
    this.paginacion.pagina,
    this.paginacion.registrosPorPagina,
    this.ultimoOrden.columna,
    this.ultimoOrden.orden
  );
}
borrarTodosFiltros() {
  this.ultimoFiltro.length = 0;
  this.filtrosMostrar.length = 0;
  this.datos = [];
  this.llenarTabla(
    this.paginacion.pagina,
    this.paginacion.registrosPorPagina,
    this.ultimoOrden.columna,
    this.ultimoOrden.orden
  );
}

}
